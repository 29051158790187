.sn_testimonials_slider {
	$ts: &;

	background-image: url('../images/obj/bg-testimonials.png');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;

	@include mq(lg){
		background-size: contain;
	}

	.swiper-container {
		.swiper-wrapper {
			align-items: stretch;

			@include mq(0px, 660px){
				height: 100% !important;
			}
		}

		&._ready {
			#{$ts}_in {
				@include mq(660px){
					position: absolute;
					top: 100%;
					transform: translateY(-100%);
				}
			}
		}
	}

	&_i {
		height: auto;
		position: relative;

		&:nth-child(odd) {
			#{$ts}_in {
				@include gradient-y($primary-light, $primary);
			}
		}

		&:nth-child(even) {
			#{$ts}_in {
				@include gradient-y($primary, $primary-light);
			}
		}

		&.swiper-slide-active {

			@include mq(660px, md-max){
				& ~ .swiper-slide {
					#{$ts}_in {
						top: 0;
						transform: translateY(0);
					}
				}
			}

			@include mq(lg){
				& + .swiper-slide {
					#{$ts}_in {
						top: 50%;
						transform: translateY(-50%);
					}

					& ~ .swiper-slide {
						#{$ts}_in {
							top: 0;
							transform: translateY(0);
						}
					}
				}
			}
		}
	}

	&_in {
		position: relative;
		overflow: hidden;
		min-height: 310px;
		padding: 20px 40px;
		border-radius: $border-radius;
		display: flex;
		align-items: center;
		color: #fff;
		transition: all 1s $transition-cubic-bezier;
		// box-shadow: 0 44px 54px 0 rgba(#7d8a9f, .13);

		&::before {
			content: '';
		  position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background-size: 90% auto;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url('../images/obj/bg-logo-red.png');
		}

		&:after{
	    content: '';
	    min-height: inherit;
	    font-size: 0;
		}

		.author {
			font-weight: fw(bold);
		}

		hr {
			@include size(13px, 3px);
			border: 0;
			background: $secondary;
			margin: 8px 0 15px;
			border-radius: 100px;
		}

		> div {
			position: relative;
			z-index: 1;
		}
	}

	&_ct {
		position: relative;
		z-index: 1;
	}

	&_quotes {
		position: absolute;
		top: 0;
		left: 0;
		width: percentage(1/3);
		max-width: 400px;
		display: none;

		@include mq(lg){
			display: block;
		}

		&_wr {
			position: relative;
		}
	}

}
