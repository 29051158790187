.sn_footer {
	// @include font-size(10px, 14px);
	// background: $secondary;
	color: #fff;
	position: relative;
	z-index: $zindex-footer-bg;
	padding-bottom: 30px;

	&_ct {
		position: relative;
		z-index: 1;
	}

	&_top {
		&_menu {
			list-style: none;

			li {
				a {
					color: #fff;
					text-transform: uppercase;
					display: block;

					&:hover,
					&:focus,
					&:active {
						color: $primary;
					}
				}

				ul {
					padding: 0;
					list-style: none;
					margin: 0;
				}
			}
		}
	}

	&_bottom {
		@include font-size(12px);
		line-height: 1.1;
		font-weight: fw(bold);
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&_col {
			width: 100%;
			margin-bottom: 15px;

			@include mq(sm){
				width: 50%;
			}

			@include mq(lg){
				width: auto !important;
				margin-bottom: 0;
				margin-right: 30px;
			}

			&:last-child {
				margin-right: 0;
			}

			&._realizedby {
				flex-grow: 1;
				width: auto;
				margin-right: 15px;

				@include mq(sm){
					width: 50%;
				}
			}

			&._socials {
				width: auto;
			}
		}

		&_menu {
			list-style: none;
			padding: 0;
			margin: 0;
			display: block;

			@include mq(sm){
				text-align: right;
			}

			@include mq(lg){
				text-align: left;
			}

			li {
				display: inline-block;

				&:last-child {
					&::after {
						content: ' - ';
						display: none;
					}
				}

				&::after {
					content: ' - ';
				}

				a {
					color: #fff;
					display: inline-block;

					&:hover,
					&:focus,
					&:active {
						color: $primary;
					}
				}
			}
		}
	}

	//
	// -681px -> the base of the SVG touches the block
	// 40px -> space from the base and the content

	&::before {
		@include gradient-x($secondary-light, #132032);
		content: '';
		position: absolute;
		top: 120px; left: 50%; bottom: 0;
		transform: translateX(-50%);
		width: 2252px;
		pointer-events: none;
	}

	&_bg {
		@include size(2252px, 656px);
	  position: absolute;
		top: (-656px + 130px);
		left: 50%;
		transform: translateX(-50%);
		pointer-events: none;
	}

	//

	&_socials {
		list-style: none;
		padding: 0;
		margin: 0;
		display: inline-flex;

		li {
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}

			a {
				@include size(24px);
				fill: #fff;
				display: block;

				&:hover {
					fill: $primary;
				}
			}
		}
	}

	&_title {
		font-weight: fw(bold);
		text-transform: uppercase;
		margin-bottom: 5px;
	}

	&_certifications {
		display: flex;
		align-items: center;
		max-width: 460px;

		a, span {
			display: block;
			width: 100%;
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}

			img {
				display: block;
				width: 100%;
			}
		}
	}

	//

	a {
		color: $primary;

		&:hover {
			color: #fff;
		}
	}

	hr {
		border-color: #fff;
		margin: 30px 0;
	}
}
