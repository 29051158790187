.sn_clients_grid {
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 45px 0 70px;

	@include mq(md){
		background-image: url('../images/obj/bg-clients.png');
	}

	&_logos {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin: 30px -10px 0;

		&_wr {
			min-width: 125px;
			margin: 10px;

			&:last-child {
				margin-right: auto;
			}
		}

		&_i {
			@include scut-ratio-box(125/70);
			width: 100%;
			background-size: contain;
			background-position: center center;
			background-repeat: no-repeat;
			transition: $transition-base;
			display: block;
			filter: grayscale(100%);

			&:hover {
				filter: grayscale(0);
			}
		}
	}
}
