.sn_courses {
	&_text {
		@include mq(lg){
			color: #fff;
		}
	}

	&_grid {
		$scg: &;

		margin-top: 30px;

		&_col {
			margin-bottom: 30px;

			@include mq(xs, sm-max){
				&:nth-child(odd) #{$scg}_i {
					@include gradient-y($secondary-light, $secondary);
					&::before { background-image: url('../images/obj/bg-logo-blue.png'); }
				}

				&:nth-child(even) #{$scg}_i {
					@include gradient-y($primary-light, $primary);
					&::before { background-image: url('../images/obj/bg-logo-red.png'); }
				}
			}

			@include mq(md, md-max){
				&:nth-child(4n+1) #{$scg}_i,
				&:nth-child(4n+4) #{$scg}_i {
					@include gradient-y($secondary-light, $secondary);
					&::before { background-image: url('../images/obj/bg-logo-blue.png'); }
				}

				&:nth-child(4n+2) #{$scg}_i,
				&:nth-child(4n+3) #{$scg}_i {
					@include gradient-y($primary-light, $primary);
					&::before { background-image: url('../images/obj/bg-logo-red.png'); }
				}
			}

			@include mq(lg){
				&:nth-child(odd) #{$scg}_i {
					@include gradient-y($secondary-light, $secondary);
					&::before { background-image: url('../images/obj/bg-logo-blue.png'); }
				}

				&:nth-child(even) #{$scg}_i {
					@include gradient-y($primary-light, $primary);
					&::before { background-image: url('../images/obj/bg-logo-red.png'); }
				}
			}
		}

		&_i {
			display: flex;
			align-items: flex-end;
			min-height: 280px;
			padding: 20px;
			border-radius: $border-radius;
			overflow: hidden;
			position: relative;

			&::before {
				content: '';
			  position: absolute;
				top: -30px; left: -30px; right: -30px; bottom: -30px;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
			}

			> div {
				position: relative;
				z-index: 1;
			}

			.title {
				text-transform: uppercase;
				color: #fff;
				font-size: 20px;
				font-weight: fw(bold);
			}
		}
	}

	&_dates {
		&_title {
			@include gradient-y($primary-light, $primary);
			@include font-size(28px, 40px, lg);
			color: #fff;
			padding: 10px 9px 4px;
			display: inline-block;
			font-weight: fw(extrabold);
			line-height: 1;
			margin-bottom: 15px;
		}

		&_list {
			font-style: italic;
		}
	}
}
