.sn_image_text {
	$sit: &;

	padding: 40px 0;

	&_wr {
		position: relative;
	}

	&_in {
		position: relative;
		z-index: 1;
	}

	&_img {
		transform: translateY(100px);
		opacity: 0;
		transition: $transition-smooth;

		&._in {
			transform: translateY(0px);
			opacity: 1;
		}
	}

	&_blob {
		position: absolute;
		top: -40px; left: -40px; right: -40px; bottom: -40px;
		background-size: contain;
		background-position: center center;
		background-repeat: no-repeat;

		&._left {
			@include mq(lg){
				right: 0;
			}
		}

		&._right {
			@include mq(lg){
				left: 0;
			}
		}

		svg {
			@include size(100%);
		}
	}


	img {
		display: inline-block;
		max-width: 100%;
		margin-right: 15px;

		&#{$sit}_img {
			display: block;
			margin: 0 auto;
		}
	}

	.btn {
		min-width: 160px;
	}
}
