.sn_map {
	margin-top: 30px;

	a {
		display: block;
	}

	img {
		width: 100%;
		display: block;
		border-radius: $border-radius;
	}
}
