.sn_prefooter {
	margin-top: 30px;
	margin-bottom: 70px;

	@include mq(md){
		margin-top: 60px;
	}

	&_ct {
		@include mq(0px, md){
			padding: 0 !important;
		}
	}

	&_in {
		position: relative;
	}

	//

	&_bg {
		@include gradient-directional(#ff5359, $primary, $deg: 82deg);
		position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: $zindex-footer-bg - 5;
		margin: 0;
		pointer-events: none;

		@include mq(md){
			border-radius: $border-radius;
		}

		img {
			max-width: 250px;
			position: absolute;
			top: 50%;
			left: 5px;
			transform: translateY(-50%);
			display: none;

			@include mq(md){
				display: block;
			}
		}
	}

	//

	&_text {
		padding: 90px 25px;
		position: relative;
		z-index: $zindex-footer-bg + 5;
		display: flex;
		align-items: center;
		max-width: 650px;
		margin: 0 auto;

		@include mq(md){
			min-height: 320px;
			padding: 70px 25px;
		}

		> div {
			width: 100%;
		}

		&:after {
	    content: '';
	    font-size: 0;
			display: none;

			@include mq(md){
				min-height: 320px - (70px * 2);
				display: block;
			}
		}

		.sn_form {
			margin-top: 35px;

			.form-group {
				position: relative;
				margin-bottom: 30px;

				.form-control {
					height: 32px;
					border-color: #fff;
					border-radius: 1000px;
					padding: 5px (100px + 20px) 5px 20px;

					@include mq(md){
						height: 55px;
						padding: 5px (150px + 35px) 5px 35px;
					}

					&:focus {
						border-color: $secondary;
					}
				}

				.btn {
					position: absolute;
					top: 0;
					right: 0;
					height: 100%;
					z-index: 1;
					padding: 0;
					width: 100px;
					min-width: 0;

					@include mq(md){
						width: 150px;
					}
				}
			}

			.custom-control {
				text-align: center;

				.custom-control-label {
					color: #fff;

					a {
						color: #fff;
						text-decoration: underline;

						&:hover,
						&:focus,
						&:active {
							color: $secondary;
						}
					}
				}
			}
		}
	}
}
