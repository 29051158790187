.sn_slider_news {
	overflow: hidden;

	&_ct {
		overflow: visible;
		margin-top: 50px;

		.swiper-wrapper {
			align-items: stretch;

			.swiper-slide {
				height: auto;
			}
		}

		.swiper-button-prev,
		.swiper-button-next {
			top: 0;
			margin: 0;
		}

		.swiper-button-prev {
			left: -25px;
			margin-left: 18px;

			@include mq(md){
				left: -8vw;
			}
		}

		.swiper-button-next {
			right: -25px;
			margin-right: 18px;

			@include mq(md){
				right: -8vw;
			}
		}

		// Animations
		&._in {
			.sn_news_i {
				transform: translateY(0px);
				opacity: 1;
			}
		}

		.swiper-slide {
			@for $i from 1 to 10 {
				&:nth-child(#{$i}) {
					.sn_news_i {
						transition-delay: #{$i / 12}s;
					}
				}
			}
		}

		.sn_news_i {
			transform: translateY(150px);
			opacity: 0;
			transition: $transition-smooth;
		}
	}


}
