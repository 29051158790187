.sn_split_text {
	&_content {
		margin-top: 30px;
		line-height: (22/14);

		@include mq(md){
			column-count: 2;
			column-gap: 30px;
		}
	}

	&_bottom {
		margin-top: 40px;
		text-align: center;

		.btn {
			margin-top: 10px;
		}
	}
}
