::selection {
  background: $primary;
  color: #000;
}

html {
  -ms-overflow-style: scrollbar;
}

a,
button {
  transition: $transition-base;
}

.noscroll {
  overflow: hidden;
}

body {
	overflow-x: hidden;

	&._of_hidden {
		overflow: hidden;
	}
}

//

.container-fluid {
	max-width: 1920px;
	padding: 0 25px;

	@include mq(md){
		padding: 0 8%;
	}

	@include mq(1921px){
		padding: 0 154px;
	}

	&._wide {
		@include mq(md){
			padding: 0 4%;
		}

		@include mq(1921px){
			padding: 0 77px;
		}
	}
}

//

// Utils.mq() classes
.sn_utils_mq_xs { display: block;	@include mq(sm){ display: none }; }
.sn_utils_mq_sm { display: none; @include mq(sm){ display: block }; @include mq(md){ display: none }; }
.sn_utils_mq_md { display: none; @include mq(md){ display: block }; @include mq(lg){ display: none }; }
.sn_utils_mq_lg { display: none; @include mq(lg){ display: block }; @include mq(xl){ display: none }; }
.sn_utils_mq_xl { display: none; @include mq(xl){ display: block }; }
