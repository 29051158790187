.sn_video {

	&_wr {
		@include scut-ratio-box(16/9);
		overflow: visible;

		> iframe {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
		}
	}

	&_img {
		position: absolute;

		&._left {
			top: 50%;
			left: -10%;
			width: 70%;
			transform: translateY(-50%);
		}

		&._right {
			top: -8%;
			right: -10%;
			width: 40%;
			transform: rotate(180deg);
		}
	}
}
