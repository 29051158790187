.sn_certifications {

	&_wr {
		display: flex;
		align-items: center;
		margin-bottom: 20px;

		a, span {
			display: block;
			width: 100%;
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}

			img {
				display: block;
				width: 100%;
			}
		}
	}
}
