.sn_quote_card {
	// @include gradient-x($secondary, $secondary-light);
	position: relative;
	margin: 30px 0;

	&_bg {
		@include size(1920px, 100%);
		margin-left: -(1920px / 2);
		position: absolute;
		top: 0; left: 50%;
		display: block;

		@include mq(xl){
			@include size(2500px, 100%);
			margin-left: -(2500px / 2);
		}
	}

	&_ct {
		position: relative;
		z-index: 1;
		padding-top: 150px;
		padding-bottom: 120px;

		@include mq(md){
			padding-top: 150px;
			padding-bottom: 100px;
		}
	}

	&_icon {
		@include size(40px);
		position: absolute;
		fill: $secondary;

		@include mq(md){
			@include size(60px);
		}

		&._quote-top {
			top: 23px;
			left: 23px;
		}

		&._quote-bottom {
			bottom: 23px;
			right: 23px;
		}
	}

	&_in {
		@include gradient-y(#F55E66, $primary);
		@include font-size(18px, 20px);
		position: relative;
		border-radius: $border-radius;
		display: flex;
		align-items: center;
		justify-content: center;
		color: #fff;
		font-weight: fw(bold);
		padding: 80px 40px;
		text-align: center;

		@include mq(md){
			padding: 70px 90px;
		}

		&::before {
			content: '';
		  position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background-size: auto 100%;
			background-position: center center;
			background-repeat: no-repeat;
			background-image: url('../images/obj/bg-logo-red.png');
		}

		> div {
			position: relative;
			z-index: 1;
		}

		.author {
			@include font-size(16px, 18px);
			font-style: italic;
			margin-top: 25px;
		}

		hr {
			@include size(14px, 2px);
			border: 0;
			background: #fff;
			margin: 5px auto 0;
			border-radius: 100px;
		}
	}
}
