
// Typography

h1, .h1 {
	@include font-size(40px, 56px);
	font-weight: fw(black);
}

h2, .h2 {
	@include font-size(28px, 40px);
	font-weight: fw(extrabold);
}

h3, .h3 {
	@include font-size(24px, 32px);
}

h4, .h4 {
	@include font-size(22px, 30px);
}

h5, .h5 {
	@include font-size(18px, 20px);
}

h6, .h6 {
	@include font-size(16px, 18px);
	font-weight: fw(medium);
	font-style: italic;
}

p {
	@include font-size(14px);
}

._p_sm {
	@include font-size(12px);
}

._p_lg {
	@include font-size(18px);
}

small {
	@include font-size(10px);
}


//

.sn_content {
	img {
		display: block;
		margin: 0 auto;
		max-width: 100%;
	}

	hr {
		@include size(19px, 4px);
		border: 0;
		background: $primary;
		margin: 8px 0 15px;
		border-radius: 100px;
	}

	ul {
		padding-left: 23px;

		li {
			padding-left: 10px;
		}
	}
}
