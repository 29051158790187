.sn_the_group {
	// @include gradient-x($secondary, $secondary-light);
	position: relative;
	margin: 30px 0;

	&_bg {
		@include size(1920px, 100%);
		margin-left: -(1920px / 2);
		position: absolute;
		top: 0; left: 50%;
		display: block;

		@include mq(xl){
			@include size(2500px, 100%);
			margin-left: -(2500px / 2);
		}
	}

	&_ct {
		position: relative;
		z-index: 1;
		padding-top: 160px;
		padding-bottom: 140px;

		@include mq(sm){
			padding-top: 170px;
			padding-bottom: 120px;
		}

		@include mq(lg){
			padding-top: 220px;
			padding-bottom: 170px;
		}

		@include mq(xl){
			padding-top: 250px;
			padding-bottom: 190px;
		}

	}

	&_list {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		margin: 0 -15px;

		@include mq(lg){
			flex-wrap: nowrap;
		}
	}

	&_i {
		fill: #fff;
		display: block;
		margin: 10px 15px;
		min-width: 150px;

		@include mq(lg){
			min-width: 0;
			flex-grow: 1;
		}

		&._academy { @include scut-ratio-box(227/45); }
		&._services { @include scut-ratio-box(228/47); }
		&._falchi { @include scut-ratio-box(211/50); }
		&._rent { @include scut-ratio-box(180/49); }
		&._protezione_civile { @include scut-ratio-box(150/61); }

		&:hover {
			fill: $primary;
		}

		// Animations
		transform: translateY(70px);
		opacity: 0;
		transition: $transition-smooth;

		@for $i from 1 to 10 {
			&:nth-child(#{$i}) {
				transition-delay: #{$i / 12}s;
			}
		}

		._in & {
			transform: translateY(0px);
			opacity: 1;
		}

		.sn_sprite {
			position: absolute;
			top: 0; left: 0;
		}
	}
}
