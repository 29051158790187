.sn_link_navigation {

	&_in {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		border-radius: $border-radius;

		._news & {
			@include gradient-x($secondary, $secondary-light);
		}

		._courses & {
			@include gradient-y($secondary-light, $secondary);
		}

		.btns {
			z-index: 1;
			margin: 30px auto;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.btn {
				margin-bottom: 15px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&_i {
		@include gradient-y($primary-light, $primary);
		border-radius: $border-radius;
		display: flex;
		align-items: flex-end;
		padding: 20px;
		width: 100%;
		min-height: 200px;
		position: relative;
		overflow: hidden;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;

		@include mq(md){
			width: percentage(1/3);
			min-height: 280px;
		}

		._news & {
			background-blend-mode: multiply;

			&:hover,
			&:focus,
			&:active {
				background-color: $primary;
			}
		}

		._courses & {
			&::before {
				content: '';
			  position: absolute;
				top: -30px; left: -30px; right: -30px; bottom: -30px;
				background-size: contain;
				background-position: center center;
				background-repeat: no-repeat;
				background-image: url('../images/obj/bg-logo-red.png');
			}
		}

		> div {
			position: relative;
			z-index: 1;
		}

		.title {
			text-transform: uppercase;
			color: #fff;
			font-size: 20px;
			font-weight: fw(bold);
			line-height: 1;
			margin-bottom: 5px;
		}

	}

}
