.sn_news_grid {

	&_top {
		text-align: center;
		margin-bottom: 50px;

		.sn_form {
			margin-top: 20px;
			margin-bottom: 40px;

			.form-group {
				margin: 0 auto;
				position: relative;
				max-width: 380px;

				.form-control {
					color: #fff;
					background: $secondary;
					height: 54px;
					border: 0;
					padding: 5px 65px 5px 24px;

					@include placeholder {
						color: #fff;
					};
				}

				button {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					width: 65px;
					display: flex;
					align-items: center;
					justify-content: center;
					fill: #fff;
					background: none;
					border: 0;
					padding: 0;
					cursor: pointer;

					&:hover {
						fill: $primary;
					}

					.sn_sprite {
						@include size(17px);
					}
				}
			}
		}
	}

	&_tabs {
		ul {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				display: inline-block;
				margin-right: 15px;

				@include mq(md){
					margin-right: 25px;
				}

				&:last-child {
					margin-right: 0;
				}

				&._active {
					a {
						pointer-events: none;
						font-weight: fw(black);

						&::before {
							width: 20px;
							background: $primary;
						}
					}
				}

				a {
					position: relative;
					text-transform: uppercase;
					display: block;
					color: $secondary;
					padding-bottom: 4px;

					&:hover,
					&:focus,
					&:active {
						&::before {
							width: 100%;
						}
					}

					&::before {
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 0;
						height: 4px;
						border-radius: 100px;
						background: $secondary;
						transition: $transition-smooth;
					}
				}
			}
		}
	}
}
