.sn_gallery_grid {

	&_row {
		align-items: stretch;
	}

	&_col {
		margin-bottom: $grid-gutter-width;
	}

	&_i {
		border-radius: $border-radius;
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-blend-mode: multiply;
		text-transform: uppercase;
		font-weight: fw(bold);
		color: #fff;
		display: flex;
		align-items: flex-end;
		padding: 15px;
		min-height: 220px;
		height: 100%;
		position: relative;
		overflow: hidden;
		box-shadow: 0 2px 20px rgba(#7D8A9F, .13);

		@include mq(lg){
			min-height: 280px;
		}

		&:after {
	    content: '';
	    min-height: inherit;
	    font-size: 0;
		}

		&:hover {
			color: #fff;
			background-color: $primary;
		}

		._detail & {
			@include scut-ratio-box(260/205);
			min-height: 0;
		}

		&_gradient {
			@include gradient-y(rgba(#000, 0), rgba(#000, .7));
	    content: '';
			position: absolute;
			left: 0; right: 0; bottom: 0;
			height: 60%;
		}

		> div {
			position: relative;
			z-index: 1;
		}

		.title {
			@extend .h5;
			color: #fff;
			line-height: (24/20);
			margin-bottom: 8px;
		}
	}

	&_overlay {
		position: fixed;
		top: 0; left: 0; right: 0; bottom: 0;
		z-index: $zindex-sticky + 50;
		overflow: hidden;
		padding: 40px;
		transition: $transition-smooth;
		user-select: none;
		transform: translate3d(100%, 0, 0);

		@include mq(md){
			padding: 70px;
		}

		&._open {
			transform: translate3d(0, 0, 0);
		}

		&_bd {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			background: rgba(#000, .6);
		}

		.swiper-container {
			@include size(100%);
			overflow: visible;
			max-width: 1920px;
			margin: 0 auto;
			pointer-events: none;

			.swiper-wrapper {
				align-items: stretch;

				.swiper-slide {
					height: auto;
					position: relative;

					img {
						position: absolute;
						border-radius: $border-radius;
						pointer-events: auto;

						&._h {
							@include size(100%, auto);
							left: 0;
							top: 50%;
							transform: translateY(-50%);
						}

						&._v {
							@include size(auto, 100%);
							top: 0;
							left: 50%;
							transform: translateX(-50%);
						}
					}
				}
			}

			.swiper-button-prev {
				left: auto;
				right: 100%;
				margin-right: 5px;

				@include mq(md){
					margin-right: 10px;
				}
			}

			.swiper-button-next {
				right: auto;
				left: 100%;
				margin-left: 5px;

				@include mq(md){
					margin-left: 10px;
				}
			}

			.swiper-button-prev,
			.swiper-button-next {
				pointer-events: auto;

				@include mq(0px, sm-max){
					@include size(30px);
					padding: 8px;
					margin-top: -15px;
				}
			}
		}
	}
}
