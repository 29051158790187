.swiper-pagination {

	&.swiper-pagination-bullets {
		position: static;
		font-size: 0;

		&._dark {
			&::before {
				background: $secondary;
			}

			.swiper-pagination-bullet {
				background: $secondary;
			}
		}

		&::before {
			@include size(0px, 2px);
			content: '';
			background: #fff;
			// background: $secondary;
			display: inline-block;
			vertical-align: top;
			margin-right: 10px;
			margin-top: (16px - 2px) / 2;
			transition: $transition-smooth;
		}

		.swiper-pagination-bullet {
			@include size(16px);
			transform: scale(.4);
			margin: 0 5px;
			opacity: 1;
			background: #fff;
			// background: $secondary;
			transition: $transition-smooth;

			&:hover,
			&:focus,
			&:active {
				outline: 0 !important;
			}

			&.swiper-pagination-bullet-active {
				transform: scale(1);
				background: $primary;
			}
		}
	}

	// Animations
	&._in {
		&.swiper-pagination-bullets {
			&::before {
				width: 75px;
			}
		}
	}
}

//

.swiper-button-prev,
.swiper-button-next {
	@include size(50px);
	border: 2px solid #fff;
	padding: 16px;
	background: none;
	border-radius: 100%;
	fill: #fff;
	transition: $transition-base;
	transform: scale3d(1, 1, 1);
	margin-top: -25px;

	&.swiper-button-disabled {
		opacity: 1;
		transform: scale3d(0, 0, 0);
	}

	&:hover,
	&:focus,
	&:active {
		background: #fff;
		fill: $primary;
	}
}

.swiper-button-prev {
	padding: 16px 17px 16px 15px;
}

.swiper-button-next {
	padding: 16px 15px 16px 17px;
}
