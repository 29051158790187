.sn_test_field_grid {
	margin-top: 30px;

	&_i {
		box-shadow: 0 40px 50px 0 rgba(#7d8a9f, .13);
		border-radius: $border-radius;
		background: #fff;

		img {
			display: block;
			width: 100%;
			border-radius: $border-radius;
		}

		> div {
			padding: 30px 25px 20px;
		}

		hr {
			@include size(30px, 4px);
			border: 0;
			background: $primary;
			margin: 10px 0;
			border-radius: 100px;
		}

		.title {
			@extend .h5;
		}
	}
}
