
// Site Wrapper
.sn_site_wrapper {
	overflow: hidden;

	&_ct {
		position: relative;
		z-index: $zindex-site-wrapper;
	}
}

// Scroll Tip
.sn_scroll_tip {
	position: absolute;
	left: 2.5%;
	z-index: 1;
	padding-left: 30px + 10px;
	color: #fff;
	font-size: 10px;
	letter-spacing: 3px;
	display: none;

	transform-origin: 0% 0%;
	transform: rotate(-90deg) translateX(-100%);

	@include mq(md){
		top: 520px;
		display: block;
	}

	@include mq(lg){
		top: 660px;
	}

	.mouse {
		@include size(30px, 20px);
		border: 2px solid #fff;
		border-radius: 1000px;
		position: absolute;
		top: 50%;
		left: 0;
		margin-top: -10px;

		&::before {
			@include size(4px);
			content: '';
			position: absolute;
			top: 50%;
			right: 6px;
			margin-top: -2px;
			background: #fff;
			border-radius: 1000px;
			animation: snScrollTip 2.5s $transition-cubic-bezier infinite;
		}
	}
}

@keyframes snScrollTip {
	0% {
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
	40% {
		opacity: 1;
	}
	55% {
		transform: translate3d(-10px, 0, 0);
		opacity: 0;
	}
	56% {
		transform: translate3d(0, 0, 0);
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

// Breadcrumb
.sn_breadcrumb {
	position: absolute;
	top: 330px;
	left: 2.5%;
	color: #fff;
	z-index: 1;
	transform-origin: 0% 0%;
	transform: rotate(-90deg) translateX(-100%);
	font-weight: fw(bold);
	display: none;

	@include mq(md){
		display: block;
	}

	&._dark {
		.breadcrumb {
			.breadcrumb-item {

				&,
				&::before,
				a {
					color: $body-color;
				}
			}
		}
	}

	.breadcrumb {
		margin: 0;

		.breadcrumb-item {
			@include font-size(12px);
			color: #fff;

			a {
				color: #fff;

				&:hover,
				&:focus,
				&:active {
					color: $primary;
				}
			}
		}
	}
}

// Pagination
.sn_pagination {
	margin-top: 50px;

	nav {
		.pagination {
			justify-content: center;
			margin: 0;

			.page-item {
				margin: 0 3px;

				.page-link {
					@include size(60px);
					border-radius: $border-radius;
					display: flex;
					align-items: center;
					justify-content: center;

					.sn_sprite {
						@include size(12px);
						fill: currentColor;
					}
				}
			}
		}
	}
}

// News Item
.sn_news_i {
	color: $body-color;
	display: flex;
	flex-direction: column;
	height: 100%;

	&:hover {
		color: $primary;

		figure {
			background-color: $primary;
		}
	}

	figure {
		@include scut-ratio-box(359/246);
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-blend-mode: multiply;
		border-radius: $border-radius;
		transition: $transition-base;
	}

	.title {
		@extend .h5;
		text-transform: uppercase;
		margin-bottom: 10px;
	}

	p {
		color: $body-color;
	}

}

// Link with arrow
.sn_link {
	@include font-size(12px);
	text-transform: uppercase;
	font-weight: fw(bold);
	margin-top: auto;
	line-height: 17px;
	padding-top: 2px;
	color: $body-color;
	display: block;
	transition: $transition-base;

	&._light {
		color: #fff;

		.sn_sprite {
			background: #fff;
			fill: $body-color;
		}
	}

	&._white {
		color: #fff;
	}

	.sn_sprite {
		@include size(17px);
		background: $primary;
		fill: #fff;
		border-radius: 100%;
		padding: 4px;
		margin-top: -2px;
		margin-left: 5px;
	}
}

a {
	&:hover {
		.sn_link {
			letter-spacing: 3px;
		}
	}
}

// Email list, on contacts
.sn_email_list {
	margin-top: 50px;

	a {
		display: inline-block;
		color: $secondary;
		line-height: .8;
		margin-bottom: 10px;
		word-break: break-all;

		&:hover {
			color: $primary;
		}
	}

	> div {
		@include mq(md){
			column-count: 2;
		}

		@include mq(lg){
			column-count: 3;
		}
	}
}

//

// Bootstrap
.alert {
	border: 0;
	color: #fff;
	padding: 15px 20px;
}
