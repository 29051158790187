.sn_intro_background {
	position: relative;

	img {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 1920px;

		@include mq(1921px){ width: 100%; }
	}

	.sn_breadcrumb {
		z-index: $zindex-site-wrapper + 10;
	}

	&_spacer {
		height: $navbar-height-mobile + 40px;

		@include mq(lg){
			height: $navbar-height + 40px;
		}
	}

}
