.sn_section_title {
	$sst: &;
	text-align: center;

	&._in {
		hr {
			@include size(20px, 4px);

			@include mq(md){
				@include size(29px, 4px);
			}
		}

		//

		#{$sst}_t,
		#{$sst}_c {
			transform: translateY(0%);
		}
	}

	&._prefooter {
		color: #fff;

		hr {
			background: #fff;
		}
	}

	&._light {
		color: #fff;
	}

	&._dark {
		hr {
			background: $secondary;
		}
	}

	//

	&_wr {
		overflow: hidden;
	}

	&_t {
		transform: translateY(100%);
		transition: $transition-smooth;
	}

	&_c {
		transform: translateY(100%);
		transition: $transition-smooth;
		transition-delay: .2s;
	}

	//

	hr {
		@include size(0px, 4px);
		border: 0;
		background: $primary;
		margin: 11px auto 13px;
		border-radius: 100px;
		transition: $transition-smooth;
		transition-delay: .1s;

		@include mq(md){
			margin: 12px auto 17px;
		}
	}

	h6 {
		line-height: 1.33;
	}
}
