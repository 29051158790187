.sn_form {

	.form-group {

		.form-control {
			@include font-size(12px, 14px);
			height: 40px;
			background-clip: border-box;

			@include mq(md){
				height: 50px;
			}

			@include placeholder {
				text-transform: uppercase;
			};
		}

		textarea.form-control {
			height: auto;
			padding: 15px;
		}
	}

	.custom-select {
		height: 45px;

		@include mq(md){
			height: 50px;
		}
	}

	.custom-control {
		padding-left: 0;

		&.custom-checkbox {
			.custom-control-input {
				&:checked {
					& ~ .custom-control-label {
						&::after {
							border-radius: 2px;
						}
					}
				}
			}
			.custom-control-label {
				&::before {
					border-radius: 4px;
				}

				&::after {
					border-radius: 100%;
				}
			}
		}

		&.custom-radio {
			.custom-control-label {
				&::after {
					border-radius: 100%;
				}
			}
		}

		.custom-control-input {
			&:checked {
				& ~ .custom-control-label {
					&::after {
						transform: scale3d(1, 1, 1);
					}
				}
			}

			&:focus {
				& ~ .custom-control-label {
					&::before {
						border-color: $secondary;
					}
				}
			}

			&:active {
				& ~ .custom-control-label {
					&::before {
						border-color: $secondary;
						background: rgba($secondary, .0);
					}
				}
			}
		}

		.custom-control-label {
			padding-left: 35px;
			text-transform: uppercase;
			font-weight: fw(bold);
			font-size: 10px;
			min-height: 22px;
			display: inline-block;
			text-align: left;
			padding-top: 4px;

			&::before {
				@include size(22px);
				border: 2px solid $secondary;
				background: #fff;
				top: 50%;
				left: 0;
				margin-top: -(22px / 2);
				box-shadow: none;
			}

			&::after {
				@include size(14px);
				top: 50%;
				left: (22px - 14px) / 2;
				margin-top: -(14px / 2);
				background: $secondary !important;
				transition: $transition-smooth-slow;
				transform: scale3d(0, 0, 0);
			}
		}
	}

	//

	&_file {
		position: relative;

		&._selected {
			> label {
				.files {
					display: inline;
				}

				.placeholder {
					display: none;
				}
			}
		}

		> input {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
		  z-index: -1;
		  width: 100%;
		  margin: 0;
		  opacity: 0;

			@each $lang, $value in $custom-file-text {
				&:lang(#{$lang}) ~ label::before {
					content: $value;
				}
			}

			&.is-invalid ~ label {
				color: $primary;

				&:hover {
					&::before {
						background: $secondary;
					}
				}

				&::before {
					background: $primary;
				}
			}

			&.is-invalid ~ .invalid-feedback {
				display: block;
			}
		}

		> label {
			display: flex;
			align-items: center;
			font-weight: fw(bold);
			font-size: 10px;
			cursor: pointer;

			&:hover {
				&::before {
					background: $primary;
				}
			}

			&::before {
				content: '';
			  display: inline-block;
				padding: 6px 20px 4px;
				background: $secondary;
				color: #fff;
				text-transform: uppercase;
				border-radius: 1000px;
				margin-right: 10px;
				font-weight: fw(medium);
				transition: $transition-base;
			}

			.files {
				display: none;
			}
		}
	}

	//

	.btn {
		min-width: 160px;
	}

}
