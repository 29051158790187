body._menu_open {
	@include mq(0px, md-max){
		overflow: hidden;
	}
}

.sn_header {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	padding: 0 25px;
	display: flex;
	align-items: center;
	z-index: $zindex-fixed;

	@include mq(lg){
		padding: 0 20px;
	}

	@include mq(xl){
		padding: 0 40px;
	}

	&::before {
		@include gradient-y($primary-light, #e73036);
		content: '';
	  position: absolute;
		top: 0; left: 0; right: 0; bottom: 0;
		transition: $transition-smooth-slow;
		transform: translate3d(0, -100%, 0);
	}

	//

	&._sticky {

		&::before {
			transform: translate3d(0, 0, 0);
		}

		.navbar {
			// height: $navbar-height-mobile-sticky;

			@include mq(lg){
				height: $navbar-height-sticky;
			}

			.navbar-brand {
				&:hover,
				&:focus,
				&:active {
					.sn_sprite {
						fill: $secondary !important;
					}
				}

				.sn_sprite {

					&._logo-corporate {
						@include mq(xl){
							@include size(118px, 57px);
						}
					}

					&._logo-academy {
						// @include mq(xl){
						// 	@include size(215px, 44px);
						// }
					}

					&._logo-services {
						// @include mq(xl){
						// 	@include size(207px, 45px);
						// }
					}

					&._logo-falchi {
						// @include mq(xl){
						// 	@include size(201px, 48px);
						// }
					}
				}
			}

			.sn_navbar_search {
				&:hover,
				&:focus,
				&:active {
					fill: $secondary;
				}
			}
		}

		#sn_menu {
			.navbar-nav {
				li {
					&.current-menu-item,
					&.current-menu-parent {
						&::before {
							background: $secondary;
						}
					}
					a {
						&:not(.btn) {

							&:hover {
								@include mq(lg){
									color: $secondary;
									fill: $secondary;
								}
							}
						}

						&.btn {
							@extend .btn-secondary;
						}
					}
				}
			}
		}
	}

	//

	&._bg_light:not(._sticky):not(._open) {
		.navbar {

			.navbar-brand {

				&:hover,
				&:focus,
				&:active {
					.sn_sprite {
						fill: $secondary;
					}
				}

				.sn_sprite {
					fill: $primary;
				}
			}

			.sn_navbar_search {
				fill: $secondary;

				&:hover,
				&:focus,
				&:active {
					fill: $primary;
				}
			}

			.navbar-toggler {
				.icon-bar {
					background: $secondary;
				}
			}

			@include mq(lg){
				#sn_menu {
					.navbar-nav {
						li {

							a {
								&:not(.btn) {
									color: $secondary;
									fill: $secondary;

									&:hover {
										color: $primary;
										fill: $primary;
									}
								}
							}

							.sn_menu_collapse {
								ul {
									li {
										a:not(.btn) {
											color: #fff;
											fill: #fff;

											&:hover {
												color: $secondary;
												fill: $secondary;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	//

	&._bg_red:not(._sticky):not(._open) {
		.navbar {

			.navbar-brand {

				&:hover,
				&:focus,
				&:active {
					.sn_sprite {
						fill: $secondary;
					}
				}
			}

			@include mq(lg){
				#sn_menu {
					.navbar-nav {
						li {
							&.current-menu-item,
							&.current-menu-parent {
								&::before {
									background: $secondary;
								}
							}

							a {
								&:not(.btn) {

									&:hover {
										color: $secondary;
										fill: $secondary;
									}
								}
							}

							.sn_menu_collapse {
								ul {
									li {
										a:not(.btn) {
											color: #fff;
											fill: #fff;

											&:hover {
												color: $secondary;
												fill: $secondary;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}

	//

	.navbar {
		position: relative;
		z-index: 1;
		padding: 0;
		width: 100%;
		justify-content: flex-start;
		height: $navbar-height-mobile;
		transition: $transition-smooth-slow;
		max-width: 1920px;
		margin: 0 auto;

		@include mq(lg){
			height: $navbar-height;
			justify-content: space-between;
		}

		.navbar-brand {
			padding: 0;
			position: relative;
			z-index: 1;
			transition: $transition-smooth-slow;

			&:hover,
			&:focus,
			&:active {
				.sn_sprite {
					fill: $primary;
				}
			}

			.sn_sprite {
				fill: #fff;
				transition: $transition-smooth-slow;

				&._logo-corporate {
					@include size(107px, 52px);

					@include mq(xl){
						@include size(153px, 74px);
					}
				}

				&._logo-academy {
					@include size(150px, 31px);

					@include mq(xl){
						@include size(215px, 44px);
					}
				}

				&._logo-services {
					@include size(150px, 33px);

					@include mq(xl){
						@include size(207px, 45px);
					}
				}

				&._logo-falchi {
					@include size(150px, 35px);

					@include mq(xl){
						@include size(201px, 48px);
					}
				}

			}
		}

		.navbar-toggler {
			@include size(31px);
			padding: 5px;
			border: 0;
			border-radius: 0;
			position: relative;
			z-index: 1;

			&:hover,
			&:focus,
			&:active {
				outline: 0;
			}

			&._open {
				.icon-bar {

					// Smaller ones
					&:nth-child(1),
					&:nth-child(4) {
						transition: $transition-smooth;
					}

					&:nth-child(1) {
						right: 20px;
					}

					&:nth-child(4) {
						left: 100%;
						right: 5px;
					}

					// Bigger ones
					&:nth-child(2),
					&:nth-child(3) {
						transition: $transition-smooth .4s;
					}

					&:nth-child(2) {
						transform: rotate3d(0, 0, 1, 45deg);
					}

					&:nth-child(3) {
						transform: rotate3d(0, 0, 1, -45deg);
					}
				}
			}

			.icon-bar {
				display: block;
				background: #fff;
				position: absolute;
				height: 2px;

				// Smaller ones
				&:nth-child(1),
				&:nth-child(4) {
					left: 31px - 5px - 15px;
					right: 5px;
					transition: $transition-smooth .4s;
				}

				&:nth-child(1) {
					top: 5px;
				}

				&:nth-child(4) {
					bottom: 5px;
				}

				// Bigger ones
				&:nth-child(2),
				&:nth-child(3) {
					top: 15px;
					left: 5px;
					right: 5px;
					margin-top: -1px;
					transition: $transition-smooth;
					transform: rotate3d(0, 0, 1, 0deg);
				}
			}
		}

		.sn_navbar_search {
			@include size(31px);
			padding: 5px;
			fill: #fff;
			margin-left: auto;
			margin-right: 10px;

			@include mq(lg){
				display: none;
			}
		}
	}

	#sn_menu {

		@include mq(lg){
			display: flex;
			align-items: center;
			justify-content: flex-end;
			flex-grow: 1;
		}

		@include mq(0px, md-max){
			@include gradient-y(#c92948, $primary);
			position: fixed;
			top: 0; left: 100%; bottom: 0;
			width: 100vw;
			padding: $navbar-height-mobile 0 0;
			overflow: hidden;
			transition: $transition-smooth;
			transform: translate3d(0, 0, 0);
		}

		&._open {
			@include mq(0px, md-max){
				transform: translate3d(-100%, 0, 0);
			}

			.navbar-nav {

				li {
					@include mq(0px, md-max){
						transform: translate3d(0, 0, 0);
					}
				}
			}
		}

		.navbar-nav {
			display: block;
			overflow-y: auto;
			overflow-x: hidden;
			height: 100%;
			padding: 25px 25px 0;

			@include mq(lg){
				display: flex;
				align-items: center;
				overflow: visible;
				padding: 0;
			}

			@include mq(0px, md-max){
				@media only screen and (min-height: 480px) {
					padding-top: 45px;
				}

				@media only screen and (min-height: 650px) {
					padding-top: 70px;
				}
			}

			// Add bottom space on menu mobile
			&::after {
				content: '';
				display: block;
				width: 100%;
				padding-top: 35px;

				@include mq(lg){
					display: none;
				}
			}

			li {
				position: relative;
				line-height: 1;
				transition: none;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				width: 100%;

				@include mq(lg){
					display: block;
					width: auto;
					margin-right: 30px;

					&:hover,
					&:focus,
					&:active {
						.sn_menu_collapse {
							display: block !important;
						}
					}
				}

				@include mq(0px, md-max){
					transition: $transition-smooth;
					transform: translate3d(300px, 0, 0);
				}

				&:last-child {
					margin-right: 0;
				}

				// For transition-delay
				@for $i from 0 through 20 {
					&:nth-child(#{$i}),
					&:nth-child(#{$i}) li {
						transition-delay: .06s * $i;
					}
				}

				&.current-menu-item,
				&.current-menu-parent {
					&::before {
						@include size(20px, 4px);
						content: '';
					  position: absolute;
						left: 0;
						bottom: 10px;
						background: $primary;
						border-radius: 100px;
						transition: $transition-smooth;
						display: none;

						@include mq(lg){
							display: block;
						}
					}
				}

				a {

					&:not(.btn) {
						@include font-size(35px, 14px, lg);
						color: #fff;
						fill: #fff;
						font-weight: fw(black);
						text-transform: capitalize;
						line-height: (44/35);
						display: inline-block;

						// Override default transition, because the font-size animates from mobile to desktop
						transition:
							color .35s $transition-cubic-bezier,
							fill .35s $transition-cubic-bezier,
							transform .35s $transition-cubic-bezier;

						@include mq(lg){
							text-transform: uppercase;
							font-weight: fw(bold);
							padding: 20px 0;
							line-height: 1.1;
						}

						&:hover {
							@include mq(lg){
								color: $primary;
								fill: $primary;
							}
						}
					}

					&.btn {
						@extend .btn-primary;
					}

					&[data-toggle="collapse"] {
						@include size(16px + (5px * 2));
						padding: 5px;
						margin-left: auto;
						transition: $transition-smooth;
						transform: rotate3d(1, 0, 0, 180deg);

						&.collapsed {
							transform: rotate3d(1, 0, 0, 0deg);
						}

						@include mq(lg){
							display: none;
						}
					}

					.sn_sprite {
						@include size(16px);
					}
				}

				.sn_menu_collapse {
					width: 100%;

					@include mq(lg){
						position: absolute;
						top: 100%;
						left: -20px;
						min-width: 270px;
						width: auto;
						display: none !important;
					}

					ul {
						padding: 5px 0 10px 25px;
						margin: 0;
						list-style: none;

						@include mq(lg){
							@include gradient-y(#c92948, $primary);
							padding: 15px;
							border-radius: $border-radius;
						}

						li {
							// transition-delay: 0s !important;
							padding: 0;
							margin-bottom: 5px;

							@include mq(lg){
								margin-bottom: 10px;
							}

							&:last-child {
								margin-bottom: 0;
							}

							&::before {
								display: none;
							}

							a {
								&:not(.btn) {
									@include font-size(25px, 14px, lg);
									padding: 0;
									line-height: 1.1;

									@include mq(lg){
										color: #fff;

										&:hover,
										&:focus,
										&:active {
											color: $secondary;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.sn_search {
	@include gradient-y(#c92948, $primary);
	position: fixed;
	top: 0; left: 0; right: 0; bottom: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: $zindex-fixed + 10;
	transition: $transition-smooth;
	transform: translate3d(0, -100%, 0);

	&._open {
		transform: translate3d(0, 0, 0);
	}

	&_in {
		text-align: center;
		padding: 0 50px;
		max-width: 100%;

		.form-group {
			margin-bottom: 10px;

			@include mq(md){
				margin-bottom: 20px;
			}

			.form-control {
				@include font-size(35px, 56px);
				color: #fff;
				background: none;
				border: 0;
				font-weight: fw(black);
				height: 75px;
				text-align: center;
				padding: 0;

				@include placeholder {
					color: #fff;
					opacity: .8;
				};

				&:focus {
					background: none;
				}
			}
		}

		.btn {
			width: 400px;
			max-width: 100%;
		}
	}

	.sn_search_toggle {
		@include size(15px + (10px * 2));
		position: absolute;
		top: 40px - 10px;
		right: 40px - 10px;
		fill: #fff;
		padding: 10px;

		&:hover,
		&:focus,
		&:active {
			fill: $secondary;
		}
	}
}
