.sn_text_card_grid {
	background-image: url('../images/svg/blobs/red4.svg');
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	padding: 50px 0;

	@include mq(md){
		padding: 80px 0;
		background-size: contain;
	}

	&_row {
		margin-top: 30px;

		@include mq(md){
			display: block;
			@include scut-clearfix;
		}
	}

	&_col {
		margin-top: 30px;

		&:first-child {
			margin-top: 0px;
		}

		@include mq(md){

			&:nth-child(2n+1) {
				// border: 1px solid black;
				float: left;
				clear: left;
			}

			&:nth-child(2n+2) {
				// border: 1px solid green;
				float: right;
				clear: right;
			}
		}
	}

	&_i {
		@extend .sn_content;
		padding: 20px;
		border-radius: $border-radius;
		background: #fff;

		@include mq(md){
			display: inline-block;
		}
	}
}
