.btn {
	text-transform: uppercase;
	min-width: 100px;
	padding: 13px 25px 11px;
}

.btn-primary {
	border: 0;
	background: none;
	position: relative;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		border-radius: $btn-border-radius;
		transition: $btn-transition;
	}

	&::before {
		@include gradient-y($primary-light, #e73036);
		transition: $btn-transition;
	}

	&::after {
		@include gradient-y(#e73036, $primary-light);
		opacity: 0;
	}

	&:hover,
	&:focus,
	&:active {
		background: none;

		&::before {
			// opacity: 0;
		}

		&::after {
			opacity: 1;
		}
	}

}

.btn-secondary {
	border: 0;
	background: none;
	position: relative;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: -1;
		border-radius: $btn-border-radius;
		transition: $btn-transition;
	}

	&::before {
		@include gradient-y($secondary-light, #132032);
		transition: $btn-transition;
	}

	&::after {
		@include gradient-y(#132032, $secondary-light);
		opacity: 0;
	}

	&:hover,
	&:focus,
	&:active {
		background: none;

		&::before {
			// opacity: 0;
		}

		&::after {
			opacity: 1;
		}
	}

}
