// Colors
$primary-light: #f0516f;
$secondary-light: #002962;

// Zindex
$zindex-footer-bg: 100;
$zindex-site-wrapper: 200;

// Font family
$font-family-serif: times, serif;

// Structure
$navbar-height: 110px;
$navbar-height-sticky: 80px;
$navbar-height-mobile: 85px;
$navbar-height-mobile-sticky: 70px;

// Font Weight
$font-weights: (
  thin				: 100,
	extralight	: 200,
	light				: 300,
	regular			: 400,
	medium			: 500,
	semibold		: 600,
	bold				: 700,
	extrabold		: 800,
	black				: 900
);

// Transition
$transition-cubic-bezier: cubic-bezier(0.4, 0, 0.2, 1);
$transition-smooth: all .6s $transition-cubic-bezier;
$transition-smooth-slow: all .45s $transition-cubic-bezier;
