.sn_intro_slider {
	position: relative;
	margin-bottom: -150px;

	@include mq(lg){
		margin-bottom: -200px;
	}

	&._small {
		margin-bottom: 0;

		.sn_breadcrumb {
			top: 280px;
		}
	}

	&_clip {
		// 'Hide' the SVG used for the 'clip-path' defs
		position: absolute;
		top: 0;
		left: -99999px;
	}

	&_bg {
		@include scut-ratio-box(2191/960);
		margin-left: 50vw;
		transform: translateX(-50%);

		// 2191 : 960 = x : 765
		width: 1745px;

		@include mq(lg){
			width: 2191px;
		}

		._small & {
			@include scut-ratio-box(2080/534);
			width: 1657px;

			@include mq(lg){
				width: 2080px;
			}
		}

		&_svg {
			position: absolute;
			top: 0; left: 0; right: 0; bottom: 0;
			width: 100%;
			height: 100%;

			rect {
				fill: #000;
				opacity: .4;
			}
		}
	}

	&_content {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translateY(-50%);
		color: #fff;
		padding-bottom: 155px;

		@include mq(lg){
			padding-bottom: 205px;
		}

		._small & {
			transform: none;
			padding-bottom: 50px;

			@include mq(lg){
				padding-bottom: 90px;
			}
		}

		&_ct {
			@include mq(0px, md-max){
				padding-right: 35px;
			}
		}

		.pretitle {
			@extend .h6;
			margin-bottom: 10px;

			@include mq(lg){
				margin-bottom: 20px;
			}
		}

		.title {
			@extend .h1;
			margin-bottom: 0;
			font-size: 30px;

			@include mq(sm){
				@include font-size(35px, 56px, lg);
			}
		}

		.subtitle {
			@extend ._p_sm;
			margin-top: 20px;
		}

		.btn {
			margin-top: 20px;

			@include mq(lg){
				margin-top: 40px;
			}
		}
	}

	&_socials {
		position: absolute;
		right: 5px;
		z-index: 1;
		top: 440px;

		@include mq(md){
			right: 2.5%;
			top: 480px;
		}

		@include mq(lg){
			top: 650px;
		}

		@include mq(xl){
			top: 620px;
		}

		ul {
			list-style: none;
			padding: 0;
			margin: 0;

			li {
				display: block;
				margin-bottom: 8px;

				a {
					@include size(24px);
					display: block;
					fill: #fff;

					&:hover,
					&:focus,
					&:active {
						fill: $primary;
					}
				}
			}
		}
	}

	&_sides {
		position: relative;
		max-width: 1920px;
		margin: 0 auto;
		z-index: 10;
	}

	.swiper-slide {
		overflow: hidden;
	}

	.swiper-pagination {
		position: absolute;
		top: 305px;
		bottom: auto;
		left: auto;
		right: 8px;
		width: auto;
		transform-origin: 100% 0;
		transform: rotate(90deg) translateX(50%);

		@include mq(md){
			right: 2.5%;
		}

		@include mq(lg){
			top: 380px;
		}

	}
}
